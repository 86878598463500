<template>
    <div class="head">
        <div class="content">
            <img src="../assets/more.png" class="menu-close" @click="show = !show"/>
            <div class="logo" @click="toHome">
                <div class="logos">
                    <img src="../assets/logo1.png" class="logo1"/>
                    <img src="../assets/logo2.png" class="logo1"/>
                </div>
            </div>
            <div class="rows">
                <div class="row-left">
                    <div class="row-top" @click="toShop(index)" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)" v-for="(item,index) in product" :key="item.id">
                        <div class="top-images" :class="{ 'title-check': indexs === index && router === '/shop'}">
                            <img v-if="item.classificationIcon" :src="item.classificationIcon" class="classificationIcon"/>
                        </div>
                        <div class="top-name">
                            {{ item.category }}
                        </div>
                    </div>
                </div>
                <div class="row-top" @click="toUs">
                    <div class="top-images" :class="{ 'title-check': router === '/contactUs'}">
                        <img src="../assets/contactUs.png" class="classificationIcon"/>
                    </div>
                    <div class="top-name">{{ $t('contactUs') }}</div>
                </div>
            </div>
            <div style="cursor: pointer;">
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{ lan === 'EN' ? 'English' : 'Español' }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="EN">English</el-dropdown-item>
                        <el-dropdown-item command="IT">Español</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <img @click="search" src="../assets/search.png" class="search"/>
                <img class="nones" @click="toPage('/login')" src="../assets/login.png" style="margin-left: 20px;"/>
                <img class="nones" @click="getCart" src="../assets/shopping-cart.png" style="margin-left: 20px;"/>
            </div>
        </div>
        <div class="select" :class="{ 'select-block': selectShow }" @mouseenter="selectMouseenter" @mouseleave="selectMouseleave">
            <div class="select-content">
                <div class="select-item" v-for="(item,index) in selectData" :key="index">
                    <div class="item-name" @click="toShop(currentIndex, index)">{{ item.category }}</div>
                    <div class="item-value" @click="toPageBuy(currentIndex, index, y)" v-for="(i,y) in item.items" :key="y">{{ i.product_name }}</div>
                </div>
                <img v-if="selectData[0] && selectData[0].categoryImages" :src="selectData[0].categoryImages" class="ban"/>
                <img v-if="selectData[1] && selectData[1].categoryImages" :src="selectData[1].categoryImages" style="margin-left: 50px"/>
            </div>
        </div>
        <div class="search">
            <van-popup v-model="searchShow" position="top">
                <div class="relatives">
                    <div class="right">
                        <div class="right-padding">
                            <div class="border-bottom">
                                <input v-model="searchValue" @input="searchInput" :placeholder="$t('searchFrom')" class="search-input"/>
                                <div class="clear">{{ $t('clear') }}</div>
                                <div class="close" @click="searchShow = false">
                                    <img src="../assets/close.png"/>
                                </div>
                            </div>
                        </div>
                        <div class="product">{{ $t('produce') }}</div>
                        <div class="scroll">
                            <img src="../assets/search-none.png" v-if="searchData.length === 0"/>
                            <template v-else>
                                <div class="scroll-row" v-for="(item,index) in searchData" :key="index" @click="toBuy(item)">
                                    <img :src="item.skuData[0] ? item.skuData[0].skuImg : item.product_image_url" class="images"/>
                                    <div class="m-l-17">
                                        <div class="shop-title">{{ item.product_name }}</div>
                                        <div class="shop-price">From ${{ item.skuData[0] ? item.skuData[0].price : '' }}</div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </van-popup>
            <van-popup v-model="cart" position="top">
                <div class="relatives">
                    <div class="right" style="padding-bottom: 20px">
                        <div class="cart-padding">
                            <div class="cart-rows">
                                <div class="round"></div>
                                <span class="shop-number">{{ shopCart.length }} {{ $t('itemsCart') }}</span>
                            </div>
                            <div class="cart-rows" @click="cart = false">
                                <div class="close" style="padding: 5px">
                                    <img src="../assets/close.png"/>
                                </div>
                                <div class="clear" style="padding: 5px">{{ $t('clear') }}</div>
                            </div>
                        </div>
                        <div class="scroll" v-loading="loading">
                            <img src="../assets/none.png" v-if="shopCart.length === 0"/>
                            <template v-else>
                                <div class="scroll-row scroll-border" v-for="(item,index) in shopCart" :key="index">
                                    <img :src="item.skuImg" class="images"/>
                                    <div class="m-l-17">
                                        <div class="shop-title">{{ item.product.product_name }}</div>
                                        <div class="shop-price">{{ $t('from') }} ${{ item.totalPrice }}</div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="view" @click="toPage('/shoppingCart')">{{ $t('viewCart') }} ({{ shopCart.length }})</div>
                    </div>
                </div>
            </van-popup>
        </div>
        <div class="left-menu" :class="{ 'left-menu-with': show }">
            <div class="menu-item" @click="toHome">
                <div>
                    <img src="../assets/homes.png" style="width: 15px;height: 15px">
                </div>
                <div class="menu-name">{{ $t('home') }}</div>
            </div>
            <div class="menu-item" v-for="(item,index) in product" :key="index" @click="expandShow(index, item)">
                <div style="width: 10px">
                    <span v-if="item.children && item.children.length > 0">
                        {{ item.show ? '-' : '+' }}
                    </span>
                </div>
                <div class="menu-name">
                    <div>{{ item.category }}</div>
                    <div v-show="item.show">
                        <div @click="toShop(index, i)" class="top-name-item" v-for="(value, i) in item.children" :key="i">{{ value.category }}</div>
                    </div>
                </div>
            </div>
            <div class="menu-item" @click="toUs">
                <div style="width: 10px"></div>
                <div class="menu-name">
                    {{ $t('contactUs') }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getToken } from '@/util/cookie';
    export default{
        data() {
            return {
                show: false,
                searchShow: false,
                cart: false,
                logo: '',
                loading: false,
                websiteId: '',
                shopCart: [],
                product: [],
                searchValue: '',
                productAll: [],
                searchData: [],
                timer: null,
                selectShow: false,
                setTime: null,
                selectData: [],
                currentIndex: '',
                indexs: '',
                lan: window.sessionStorage.getItem('imposta_lingua') || 'EN'
            }
        },
        mounted() {
            this.init();
            this.$EventBus.$on('Refresh', () => {
                this.init()
                this.lan = window.sessionStorage.getItem('imposta_lingua');
            })
            this.$EventBus.$on('headIndex', (index) => {
                this.indexs = index;
            })
        },
        destroyed() {
            this.$EventBus.$off('Refresh');
            this.$EventBus.$off('headIndex');
        },
        computed: {
            router() {
                return this.$route.path
            }
        },
        methods:{
            init() {
                let webData = {};
                if (window.sessionStorage.getItem('imposta_lingua') === 'IT') {
                    webData = JSON.parse(window.sessionStorage.getItem('webData2'));
                } else {
                    webData = JSON.parse(window.sessionStorage.getItem('webData'));
                }
                this.logo = webData.logo.logo_url;
                this.websiteId = webData._id;
                // 这是购物车
                if (webData.products) {
                    webData.products.forEach((el,index) => {
                        el.show = false;
                        if (el.items) {
                            const item = el.items.reduce((result, current, i) => {
                                result.push({ ...current, classId: index, shopId: i })
                                return result
                            }, [])
                            this.productAll = this.productAll.concat(item)
                        }
                    })
                };
                this.product = webData.products;
            },
            handleCommand(com) {
                if (this.lan === com) return;
                this.lan = com;
                window.sessionStorage.setItem('imposta_lingua', com)
                this.$i18n.locale = com;
                this.$EventBus.$emit('Refresh')
            },
            toPage(path) {
                if (path === '/login' && getToken()) {
                    path = '/order'
                    this.$router.push(path)
                    return;
                } 
                if (path === this.$route.path) return
                if (path === '/shoppingCart') {
                    this.cart = false
                }
                this.$router.push(path)
            },
            toHome() {
                this.show = false;
                if (this.$route.path === '/') return;
                this.$router.push('/')
            },
            toUs() {
                this.show = false;
                if (this.$route.path === '/contactUs') return;
                this.$router.push('/contactUs')
            },
            // 搜索点击事件
            search() {
                this.searchValue = "";
                this.searchShow = true;
            },
            // 获取购物车的信息
            getCart() {
                const id = getToken();
                if (id) {
                    this.cart = true;
                    this.loading = true;
                    window.cloudInit.callFunction({
                        name: 'webFunction',
                        data: {
                            method: 'getCart',
                            accountId: id,
                            websiteId: this.websiteId
                        }
                    }).then((res) => {
                        this.loading = false
                        if (res.result.code === 200) {
                            this.shopCart = res.result.data;
                        } else {
                            this.$message.error(res.result.errMsg);
                        }
                    }).catch(() => {
                        this.loading = false
                    })
                } else {
                    if (this.$route.path !== '/login') {
                        this.$router.push('/login')
                    }
                }
            },
            searchInput() {
                // 清除之前的定时器
                clearTimeout(this.timer);
                // 设置新的定时器
                this.timer = setTimeout(() => {
                    if (this.searchValue) {
                        this.searchData = [];
                        this.productAll.forEach(el => {
                            if (el.product_name.indexOf(this.searchValue) > -1) {
                                this.searchData.push(el);
                            }
                        })
                    }
                }, 500);
            },
            toBuy(item) {
                if (this.$route.path === '/buy') {
                    this.$router.replace({
                        path: '/buy',
                        query: {
                            id: item.classId,
                            shopId: item.shopId
                        }
                    })
                    this.$EventBus.$emit('globalValueChanged', '');
                } else {
                    this.$router.push({
                        path: '/buy',
                        query: {
                            id: item.classId,
                            shopId: item.shopId
                        }
                    })
                }
                this.searchShow = false
            },
            // 鼠标移入事件
            mouseenter(index) {
                let data = this.product[index].children;
                if (data && data.length > 0) {
                    this.selectData = data;
                    this.selectShow = true;
                    this.currentIndex = index;
                }
            },
            mouseleave(index) {
                if (index == this.currentIndex){
                    this.setTime = setTimeout(() => {
                        this.selectShow = false
                    }, 100);
                };
            },
            selectMouseenter() {
                clearTimeout(this.setTime)
            },
            selectMouseleave() {
                this.selectShow = false
            },
            // 展开关闭
            expandShow(index, item) {
                if (item.children && item.children.length > 0) {
                    this.product[index].show = !this.product[index].show
                } else {
                    this.toShop(index)
                }
                
            },
            toShop(index, i) {
                window.sessionStorage.setItem('shopPage', JSON.stringify({ id: index, shopId: i }))
                if (this.$route.path === '/shop') {
                    this.$EventBus.$emit('pageShop');
                } else {
                    this.$router.push({
                        path: '/shop'
                    });
                };
                this.indexs = index;
                this.show = false;
            },
            toPageBuy(index, i, y) {
                window.sessionStorage.setItem('buyPage', JSON.stringify({ id: this.product[index].id, classId: this.product[index].children[i].id, shopId: this.product[index].children[i].items[y] }))
                if (this.$route.path === '/buy') {
                    this.$EventBus.$emit('pageBuy')
                } else {
                    this.$router.push({
                        path: '/buy'
                    })
                }
            }
        }
    }
</script>
<style scoped>
.head{
    background: #fff;
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.3);
    box-sizing: border-box;
    width: 100%;
    min-width: 1300px;
}
.logo{
    width: 80px;
    height: 80px;
    background-color: #E9ECEF;
    border-radius: 20px;
    box-sizing: border-box;
    padding-left: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}
.logos{
    display: flex;
    align-items: center;
    column-gap: 30px;
    position: relative;
    left: -88px;
    transition: left 0.3s ease;
}
.logo:hover .logos{
    left: 0px;
}
.search{
    width: 20px;
    height: 20px;
}
.rows{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.menu{
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 
.title-check{
    background-color: #99D8EF !important;
}
.el-dropdown-link{
    padding-right: 20px;
    font-size: 20px;
    font-weight: 500;
}
.content{
    height: 138px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
}
.menu-close{
    display: none;
}
.select{
    min-width: 1200px;
    background-color: #fff;
    width: 100%;
    top: 138px;
    z-index: 9;
    position: absolute;
    left: 0px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-sizing: border-box;
    transition: all .5s ease-out;
}
.select-content {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}
.select-block {
    max-height: 100vh;
    transition: all .5s ease-in-out;
}
.item-name{
    font-size: 18px;
}
.item-value{
    color: #898989;
    font-size: 18px;
    margin-top: 10px;
    line-height: 20px;
    cursor: pointer;
}
.select-item{
    margin-left: 80px;
    padding-top: 60px;
    
}
.ban{
    width: 233px;
    height: 180px;
    margin-left: 120px;
    padding-top: 27px;
}
.left-menu {
    display: none;
}
.classificationIcon{
    width: 42px;
    height: 42px;
    object-fit: contain;
}
@media screen and (max-width: 768px) {
  .rows {
    display: none;
  }
  .nones{
    display: none;
  }
  .menu-close{
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
  }
  .relatives{
    padding-left: 10px;
  }
  .content{
    height: 56px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .logo{
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
  .search{
    width: 20px;
    height: 20px;
  }
  .left-menu{
    position: fixed;
    top: 56px;
    left: 0px;
    z-index: 9;
    width: 0px;
    overflow: hidden;
    height: calc(100vh - 56px);
    background-color: #fff;
    display: none;
    display: block;
  }
  .left-menu-with {
    width: 80vw;
    transition: width .5s ease-in-out;
  }
  .menu-item{
    padding: 20px 27px;
    font-size: 12px;
    color: #333;
    font-weight: 700;
    border-bottom: 1px solid #B2B2B2;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    z-index: 9999;
  }
  .menu-name{
    margin-left: 10px;
  }
  .top-name-item{
    font-size: 12px;
    color: #000000;
    font-weight: 400;
    padding-top: 20px;
    padding-left: 10px;
  }
  .top-name-item:first-child{
    padding-top: 25px;
  }
  .head{
    min-width: 0px;
  }
  .el-dropdown-link{
    display: none;
  }
  .logo1{
    width: 30px;
  }
  .logos{
    left: -75px;
  }
}
.search >>> .van-popup {
    background-color: transparent;
}
.relatives{
    display: flex;
    justify-content: flex-end;
    padding-top: 120px;
    padding-right: 10px;
}
.right{
    width: 500px;
    background-color: #fff;
    border-radius: 8px;
    padding: 30px 0px 0px 40px;
    box-sizing: border-box;
}
.right-padding{
    padding-right: 40px;
}
.border-bottom{
    border-bottom: 2px solid #000000;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.search-input{
    height: 43px;
    font-size: 24px;
    flex: 1;
    border: 0px;
    padding-left: 10px;
    width: 0px;
}
.clear{
    font-size: 16px;
    color: #000000;
    padding: 10px;
    cursor: pointer;
}
.close{
    padding: 10px;
    cursor: pointer;
}
.close img{
    position: relative;
    top: 4px;
}
.product{
    margin-top: 35px;
    font-size: 26px;
    color: #000000;
    font-weight: 300;
}
.scroll{
    margin-top: 18px;
    max-height: calc(100vh - 400px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
}
.scroll-row{
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    padding-bottom: 10px;
}
.scroll-row:last-child{
    margin-bottom: 0px;
}
.m-l-17{
    margin-left: 17px;
}
.shop-title{
    font-size: 16px;
    color: #000000;
}
.shop-price{
    font-size: 16px;
    color: #606169;
    margin-top: 10px;
}
.cart-padding{
    padding-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.cart-rows{
    display: flex;
    align-items: center;
}
.round{
    width: 12px;
    height: 12px;
    border: 1px solid #000000;
    border-radius: 50%;
}
.shop-number{
    font-size: 16px;
    margin-left: 10px;
}
.scroll-border{
    border-bottom: 1px solid #CCCCCC;
}
.scroll-border:last-child{
    border: 0px;
}
.images{
    width: 100px;
    height: 120px;
    object-fit: contain;
}
.view{
    height: 53px;
    background-color: #000000;
    margin-right: 40px;
    border-radius: 100px;
    margin-top: 20px;
    text-align: center;
    line-height: 53px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}
.row-left{
    display: flex;
    align-items: flex-start;
    
}
.row-top{
    width: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 6px;
    cursor: pointer;
}
.top-name{
    font-size: 12px;
    color: #333333;
}
.top-images{
    height: 70px;
    background-color: #F3F3F3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>