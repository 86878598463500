<template>
	<div class="footer">
		<div class="content">
			<div>
				<div @click="toGetEmail">
					<img src="../assets/logo-one.png" class="logo"/>
					<div class="logo-hint">{{ $t('events') }}</div>
					<div class="email-hint">{{ $t('automatically') }}.</div>
				</div>
				<el-input placeholder="E-mail" v-model="valus" class="inputs">
					<template slot="suffix">
						<img @click="handleEnter" src="../assets/bg-right.png" class="bg-right"/>
					</template>
				</el-input>
				<div class="rows">
					<img @click="toPage('http://www.facebook.com/')" src="../assets/f.png"/>
					<img @click="toPage('http://youtube.com/')" src="../assets/ytb.png"/>
					<img @click="toPage('https://www.linkedin.cn/')" src="../assets/in.png"/>
				</div>
			</div>
			<div class="block">
				<div class="block-name">QUICK LINKS</div>
				<div class="block-item custo" @click="toPath('/')">Home</div>
				<div class="block-item custo" @click="toPath('/shop')">{{ $t('produce2') }}</div>
				<div class="block-item custo" @click="toPath('/contactUs')">{{ $t('contactUs') }}</div>
			</div>
			<div class="block">
				<div class="block-name">{{ $t('produce') }}</div>
				<div class="block-item custo" @click="toShop(index)" v-for="(item,index) in product" :key="index">
					{{ item.category }}
				</div>
			</div>
			<div class="block">
				<div class="block-name">{{ $t('SUPPORT') }}</div>
				<div class="block-item custo" v-for="item in list" :key="item._id" @click="toText(item._id)">{{ item.title }}</div>
			</div>
			<div class="positions">
				<el-dropdown @command="handleCommand">
					<span class="el-dropdown-link">
						{{ lan === 'EN' ? 'English' : 'Español' }}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="EN">English</el-dropdown-item>
						<el-dropdown-item command="IT">Español</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<img class="pays" src="../assets/pay-items.png"/>
			</div>
		</div>
	</div>
</template>
<script>
import { getToken } from '@/utils/auth';
export default {
	data() {
		return {
			valus: '',
			loading: false,
			websiteId: '',
			product: [],
			lan: window.sessionStorage.getItem('imposta_lingua') || 'EN',
			list: []
		}
	},
	mounted() {
		this.init();
		this.getText()
		this.$EventBus.$on('Refresh', () => {
			this.init()
			this.lan = window.sessionStorage.getItem('imposta_lingua');
		})
	},
	destroyed() {
		this.$EventBus.$off('Refresh')
	},
	methods:{
		init() {
			let web = {};
			if (window.sessionStorage.getItem('imposta_lingua') === 'IT') {
				web = JSON.parse(window.sessionStorage.getItem('webData2'));
			} else {
				web = JSON.parse(window.sessionStorage.getItem('webData'));
			}
			this.websiteId = web._id;
			this.product = web.products
		},
		// 获取帮助文档
		getText() {
			window.cloudInit.callFunction({
                name: 'webFunction',
                data: {
                    method: 'addHelpText',
                    type: 'list',
                    websiteId: this.websiteId
                }
            }).then((res) => {
				if (res.result.code == 200) {
					this.list = res.result.data
				}
            })
		},
		toPath(path) {
			if(this.$route.path === path) {
				window.scrollTo({
					top: 0,
					behavior: 'smooth' // 可选，平滑滚动效果
				});
				return
			};
			this.$router.push(path)
		},
		handleCommand(com) {
                if (this.lan === com) return;
                this.lan = com;
                window.sessionStorage.setItem('imposta_lingua', com)
                this.$i18n.locale = com;
                this.$EventBus.$emit('Refresh')
            },
		// 提交
		handleEnter() {
			if (!getToken()) {
				this.$router.push('/login')
				return;
			}
			if (this.loading) return;
			this.loading = true;
			let email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (!email.test(this.email)) {
                this.$message.warning('Email format error')
				this.loading = false;
                return;
            };
			window.cloudInit.callFunction({
                name: 'onlineMessageFunctions',
                data: {
                    method: 'add',
                    nickname: '',
                    contact: this.valus,
                    unit_name: '',
                    demand: '',
                    website_id: this.websiteId
                }
            }).then(() => {
                this.loading = false;
            }).catch(() => {
                this.loading = false
            })
		},
		toShop(index) {
			this.$EventBus.$emit('headIndex', index);
			window.sessionStorage.setItem('shopPage', JSON.stringify({ id: index }))
			if (this.$route.path === '/shop') {
				window.scrollTo({
					top: 0,
					behavior: 'smooth' // 可选，平滑滚动效果
				});
				this.$EventBus.$emit('pageShop');
			} else {
				this.$router.push({
					path: '/shop'
				});
			};
		},
		toGetEmail() {
			if (!getToken()) {
				this.$router.push('/login')
				return;
			};
			this.$router.push('/email')
		},
		toPage(url) {
			window.open(url)
		},
		toText(id) {
			this.$router.push({
				path: '/helpText',
				query: {
					id
				}
			})
		}
	}
}
</script>
<style scoped>
.footer{
	background-color: #222222;
	padding: 27px 0px;
}
.custo{
	cursor: pointer;
}
.content{
	width: 80%;
	color: #FFFFFF;
	min-width: 1200px;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	column-gap: 8%;
	position: relative;
}
.logo{
	width: 82px;
	height: 82px;
}
.el-dropdown-link{
    padding-right: 20px;
    font-size: 20px;
    font-weight: 500;
	color: #fff;
}
.logo-hint{
	font-size: 32px;
	color: 32px;
	font-weight: 700;
	margin-top: 20px;
}
.email-hint{
	font-size: 16px;
	color: rgba(255,255,255,0.6);
	margin-top: 10px;
}
.inputs{
	width: 360px;
	margin-top: 15px;
}
.inputs >>> input{
	background-color: transparent;
	padding-right: 45px;
	color: #fff;
}
.bg-right{
	width: 20px;
	height: 20px;
	position: relative;
	top: 10px;
	right: 10px;
}
.rows{
	display: flex;
	align-items: center;
	margin-top: 20px;
	column-gap: 15px;
}
.block{
	padding-top: 30px;
}
.block-name{
	font-size: 16px;
	color: #F2F2F2;
	margin-bottom: 20px;
}
.block-item{
	font-size: 14px;
	color: #C8C8C8;
	margin-bottom: 10px;
}
.positions{
	position: absolute;
	right: 10px;
	bottom: 0px;
	z-index: 9;
	display: flex;
	flex-direction: column;
}
.pays{
	margin-top: 40px;
	width: 100px;
	height: 20px;
}
@media screen and (max-width: 768px) {
	.content{
		min-width: 0px;
	}
	.inputs{
		width: 80%;
	}
	.positions{
		display: none;
	}
}
</style>