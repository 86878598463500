<template>
  <div>
    <div class="loading" v-if="loading">
      <div class="nb-spinner"></div>
    </div>
    <template v-else>
      <heads></heads>
      <router-view/>
      <footers v-if="path !== '/email' && path !== '/emailSuccess'"></footers>
    </template>
  </div>
</template>
<script>
import heads from "./components/head.vue"
import footers from "./components/footer.vue"
export default {
  components: { heads, footers },
  data() {
    return {
      loading: false
    }
  },
  computed:{
    path() {
      return this.$route.path
    }
  },
  watch: {
    '$route': function(to,from){
　    document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    async init() {
      this.loading = true
      // 注册微信云函数
      const cloud = new window.cloud.Cloud({
        // 必填，表示是未登录模式
        identityless: true,
        // 资源方 AppID
        resourceAppid: 'wxd2b91739417f7a9a',
        // 资源方环境 ID
        resourceEnv: 'ai-weka-cloud-3gtwcbz8ddfef45a',
      })
      await cloud.init()
      window.cloudInit = cloud;
      // 获取数据
      let res = await cloud.callFunction({
        name: 'aiWebsiteFunctions',
        data: {
          method: 'getWebsiteById',
          domain: 'xxewmsc.ai-site.cc'
        }
      })
      // let res2 = await cloud.callFunction({
      //   name: 'aiWebsiteFunctions',
      //   data: {
      //     method: 'getWebsiteById',
      //     domain: 'xxewmsc.ai-site.cc',
      //     language: 'it'
      //   }
      // })
      const websiteData = res.result.data;
      window.sessionStorage.setItem('webData2' ,JSON.stringify(websiteData));
      window.sessionStorage.setItem('webData' ,JSON.stringify(websiteData));
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
html,body,#app{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
}
.loading{
  width: 100vw;
  height: 100vh;
  background-color: #132248;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nb-spinner {
  width: 75px;
  height: 75px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #009688;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}
input[type="number"] {  
    -moz-appearance: textfield; /* Firefox */  
    -webkit-appearance: none; /* Safari 和 Chrome */  
    appearance: none;  
    margin: 0; /* 去除默认的额外间距 */  
}
input[type="number"]::-webkit-outer-spin-button,  
input[type="number"]::-webkit-inner-spin-button {  
    -webkit-appearance: none;  
    margin: 0;  
}
@font-face {
  font-family: 'DIN-Bold';
  src: url('./assets/din-bold-2.ttf');
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
