import Vue from 'vue';
import VueI18n from 'vue-i18n';
 
Vue.use(VueI18n);
 
const messages = {
  EN: {
      home: 'HOME',
      produce: 'PRODUCTS',
      produce2: 'produce',
      itemsCart:'Items in Cart',
      clear: 'Clear',
      form: 'from',
      viewCart: 'View cart',
      searchFrom:'Search for',
      ourCompany: "OUR COMPANY",
      price: 'PRICE',
      priceSy: '$',
      shopTitle: 'What Defines Hiii',
      shopContent: 'Hiii Equipment Company is a modern enterprise focusing on the development, production and sales of high-end fitness and sports equipment. Since its establishment, we have always been adhering to the corporate philosophy of "science and technology lead health, quality cast the future", committed to providing global consumers with safe, efficient and innovative sports solutions, focusing on product technological innovation and quality improvement, to ensure that every customer can get professional and intimate service.',
      learnMore: 'Learn More',
      contactUs: 'CONTACT US',
      welcome: 'WELCOME TO CONSULT',
      now: 'Now Darin machines have been exported to more than 70 countries of the world. Keeping automation, quality and service in mind, always based on the diverse needs of customers, Darin will be dedicated to providing clients with satified product and full life after sales service.',
      address: 'Address',
      message: 'ONLINE MESSAGE',
      place1: '* Please fill in your name',
      palce2: '* Please fill in your email address',
      place3: 'Please fill in your requirements',
      submit: 'submit',
      events: 'Events, new arrivals and sales',
      automatically: 'Automatically to your email box',
      SUPPORT: 'SUPPORT',
      Smart: 'Smart Fhone Enabiod App',
      LOGIN: 'LOGIN',
      Forgot: 'Forgot your password',
      Sign: 'Sign up',
      next: 'next',
      Back: 'Back to login',
      backCode: 'Back to get code',
      Shopping: 'Shopping Cart',
      Summary: 'Summary',
      Subtotal: 'Subtotal',
      OrderTotal: 'Order Total',
      Proceed:'Proceed to Checkout',
      PayUsing: 'Pay using the stripe platform',
      Update: 'Update Shopping Cart',
      Save: 'Save',
      AddtoCart: 'Add to Cart',
      Quantity: 'Quantity',
      Billingdetails: 'Billing details',
      Ordersummary: 'Order summary',
      Total: 'Total',
      PaymemntInformation: 'Paymemnt Information',
      Paynow: 'Pay now'
   },
  IT: {
      home: 'Casa',
      produce: 'Productos',
      produce2: 'Producción',
      itemsCart:'Productos en el carrito de la compra',
      clear: 'Eliminar',
      from: 'De',
      viewCart: 'Ver el carrito de la compra',
      searchFrom: 'Buscar',
      ourCompany: 'Nuestra empresa',
      price: 'Precio',
      priceSy: '$',
      shopTitle: 'Definición de hiii',
      shopContent: `Haiii Equipment Company es una empresa moderna centrada en el desarrollo, producción y venta de equipos deportivos de fitness de alta gama. Desde su creación, siempre nos hemos adherido al concepto empresarial de "la ciencia y la tecnología lideran la salud y la calidad forja el futuro", nos hemos comprometido a proporcionar soluciones deportivas seguras, eficientes e innovadoras para los consumidores globales, centrándonos en la innovación tecnológica del producto y la mejora de la calidad, y asegurando que cada cliente pueda obtener servicios profesionales e íntimos.`,
      learnMore: 'Aprende más',
      contactUs: 'Contáctanos',
      welcome: 'Bienvenido a consultar',
      now: 'En la actualidad, las máquinas Darin se han exportado a más de 70 países del mundo. Darin siempre tiene en cuenta la automatización, la calidad y el servicio, siempre se basa en las diversas necesidades de los clientes y se compromete a proporcionar a los clientes productos satisfactorios y servicio post - venta durante todo el ciclo de vida.',
      address: 'Dirección',
      message: 'Mensajes en línea',
      place1: '* Por favor, rellene su nombre.',
      palce2: '* Por favor, rellene su dirección de correo electrónico.',
      place3: 'Por favor, rellene su solicitud.',
      submit: 'Presentación',
      events: 'Actividades, nuevos productos y ventas',
      automatically: 'Enviar automáticamente a su buzón',
      SUPPORT: 'Apoyo',
      Smart: 'Aplicación enabido para teléfonos inteligentes',
      LOGIN: 'Iniciar sesión',
      Forgot: 'Olvida la contraseña',
      Sign: 'Registro',
      next: 'Siguiente',
      Back: 'Volver a iniciar sesión',
      backCode: 'Volver a obtener el Código',
      Shopping: 'Carrito de compras',
      Summary: 'Resumen',
      Subtotal: 'Subtotal',
      OrderTotal: 'Total de pedidos',
      Proceed:'Realizar la liquidación',
      PayUsing: 'Pagar con la plataforma STRIPE',
      Update: 'Actualización del carrito de la compra',
      Save: 'Guardar',
      AddtoCart: 'Añadir al carrito de la compra',
      Quantity: 'Cantidad',
      Billingdetails: 'Detalles de la factura',
      Ordersummary: "Resumen de pedidos",
      Total: 'Total',
      PaymemntInformation: 'Información de pago',
      Paynow: 'Pago inmediato'
   }
};
 
const i18n = new VueI18n({
  locale: window.sessionStorage.getItem('imposta_lingua') || 'EN', // 设置默认语言
  messages, // 设置语言环境信息
});
 
export default i18n;