import Cookies from "js-cookie"
const userid = 'TOKEN_ID';
const email = 'ACCOUNT_NAME'

export const setToken = (value) => {
    Cookies.set(userid, value)
}
export const getToken = (value) => {
    return Cookies.get(userid, value)
}
export const RemoveToken = (value) => {
    Cookies.remove(userid, value)
}

export const setAccount = (value) => {
    Cookies.set(email, value)
}
export const getAccount = (value) => {
    return Cookies.get(email, value)
}
export const RemoveAccount = (value) => {
    Cookies.remove(email, value)
}