import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from "@/util/cookie"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      name: 'HOME'
    },
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {
      name: 'SHOP'
    },
    component: () => import('@/views/shop/index.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    meta: {
      name: 'CONTACT-US'
    },
    component: () => import('@/views/contactUs/index.vue')
  },
  {
    path: '/buy',
    name: 'buy',
    meta: {
      name: 'SHOP'
    },
    component: () => import('@/views/shop/buy.vue')
  },
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    meta: {
      name: 'SHOP',
      isLogin: true
    },
    component: () => import('@/views/shop/cart.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      name: ''
    },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    meta: {
      name: 'SHOP',
      isLogin: true
    },
    component: () => import('@/views/shop/pay.vue')
  },
  {
    path: '/result',
    name: 'result',
    meta: {
      name: 'SHOP',
      isLogin: true
    },
    component: () => import('@/views/shop/payResult.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      name: 'SHOP',
      isLogin: true
    },
    component: () => import('@/views/shop/order.vue')
  },
  {
    path: '/email',
    name: 'email',
    component: () => import('@/views/getEmail/index.vue')
  },
  {
    path: '/emailSuccess',
    name: 'success',
    component: () => import('@/views/getEmail/success.vue')
  },
  {
    path: '/verifyEmail',
    name: 'verifyEmail',
    component: () => import('@/views/login/verifyEmail.vue')
  },
  {
    path: '/retrievePassword',
    name: 'retrievePassword',
    component: () => import('@/views/login/retrievePassword.vue')
  },
  {
    path: '/helpText',
    name: 'helpText',
    component: () => import('@/views/login/helpText.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 设置路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.isLogin) {
    if (getToken()) {
      next()
    } else {
      next('/login')
    }
  } else {
    next();
  }
});

export default router
