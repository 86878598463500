import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from "./il18n/index"
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

Vue.use(ElementUI);
Vue.use(Vant);

Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
